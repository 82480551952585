$vt-primary: #F9B000;
$vt-primary-light: #FEF7E5;
$vt-secondary: #312D2D;
$vt-secondary-light: #E3E3E3;
$vt-link: #003C68;
$vt-link-hover: #005EB8;
$vt-link-visited: #93328E;
$vt-error: #AA112C;
$vt-success: #00826E;
$vt-light-blue: #E5EEF7;

@font-face {
  font-family: 'HelveticaNeue';
  src: url('./assets/fonts/Bold/HelveticaNeue-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: url('./assets/fonts/Regular/HelveticaNeue-Roman.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: url('./assets/fonts/Medium/HelveticaNeue-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
