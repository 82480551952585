/* Meta */
@import "~ng-pick-datetime/assets/style/picker.min.css";
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "../node_modules/leaflet/dist/leaflet.css";
@import "variables.scss";
@import 'ngx-multiple-dates/prebuilt-themes/indigo-pink.css';

* {
  scroll-behavior: smooth;
}

body {
  font-family: 'Open sans', sans-serif;
  background-color: #fff;
  background: url("assets/cykelbakrund.jpg") no-repeat center center fixed;
  background-size: cover;
}
.content {
    overflow: hidden !important;
}

.container {
    max-width: none !important;
}



/* Universal toggles */
.mobile-only {
    display: none;
}

.desktop-only {
    display: normal;
}
.hidden {
  display: none;
}
/* Logos/headers */
.logo {
    height: 35px;
    width: auto;
  vertical-align: central;
}

/* Text content */
h1, h2, h3, h4, h5, h6 {
    font-family: 'HelveticaNeue'
}
h3 {
    font-size: 24px;
}

h4 {
    font-size: 20px;
    line-height: 24px;
}

p {
    font-size: 1rem;
}

p.info {
    font-size: 18px;
}

.bold-text {
    font-weight: bold;
}

.italic-text {
    font-style: italic;
}

a, a.non-href-link {
  color: $vt-link;
  cursor: pointer;
}

a:hover, a.non-href-link:hover {
  color: $vt-link-hover;
}


.success-text {
  color: $vt-success;
}

.error-text {
  color: $vt-error;
}

.text-under-from {
    font-size: 12px;
}

/* Buttons */
.btn {
  cursor: pointer;
  user-select: none;
  outline: none !important;
  box-shadow: none !important
}

  .btn:focus, .btn:active {
    outline: none !important;
    box-shadow: none !important
  }


.secondary-btn {
  background-color: $vt-secondary;
  border: 1px solid $vt-secondary;
  color: white;
}
.secondary-btn:disabled:hover {
  cursor: default;
  background-color: $vt-secondary;
  border: 1px solid $vt-secondary;
}
  .secondary-btn:hover {
    color: white;
    background-color: black;
    border: 1px solid black;
  }

.gray-btn {
  border: 1px solid #333;
}

.gray-btn:hover {
  color: #fff;
  background: #333;
}

.primary-btn {
  background-color: $vt-primary;
  border: 1px solid $vt-primary;
  color: $vt-secondary;
}

.primary-btn:hover {
  background-color: $vt-primary;
  border: 1px solid $vt-primary;
  color: $vt-secondary;
}

.green-empty-btn {
  border: 1px solid $vt-primary;
  color: $vt-primary;
}
  .green-empty-btn:disabled:hover {
    background-color: transparent;
    cursor: default;
    color: inherit;
  }
  .green-empty-btn:hover {
    background-color: $vt-primary;
    border: 1px solid $vt-primary;
    color: white;
  }
.red-btn {
  background: $vt-error;
  border: 1px solid $vt-error;
  color: white;
}
.red-btn:hover {
  background: #710b1d;
  border: 1px solid #710b1d;
  color: white;
}

.control-buttons {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin: 10px auto 15px auto;
  width: 100%;
}
  .control-buttons button {
    padding: 1%;
    margin: 0 auto;
    width: 45%;
  }
.add-remove-btn {
  text-align: center;
  vertical-align: middle;
  display: inline;
  box-sizing: unset;
  border-radius: 100%;
  margin: 0 !important;
  width: 1rem !important;
}

/* Forms */
.form-input {
  border: 1px solid $vt-secondary-light;
  border-radius: 0.25rem;
  padding: 0.5rem;
}

.form-input-with-icon {
  display: flex;
  flex-direction: row;
  border: 1px solid $vt-secondary-light;
  border-radius: 0.25rem;
}
.form-input-noborders {
  border: 0;
  padding: 0.5rem;
  width: 100%;
  border-radius: 0.25rem;
}
.form-valid {
  border: 1px solid $vt-primary !important
}

.form-invalid {
  border: 1px solid #800000 !important
}

input[type="time"]::-webkit-calendar-picker-indicator,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
}

.entity-add-form
{
  margin-bottom: 30px;
}

  .entity-add-form > form,
  .entity-refine-form {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 0.5rem;
  }

.entity-add-form-entry, .entity-refine-form-entry {
  display: flex;
  flex-direction: column;
}
.entity-comment {
  flex: 1;
}

.spot-selector {
  text-align: left;
  display: flex;
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.spot-selector > .form-input {
    display: inline;
    background: none !important;
    border: 0 !important;
    width: 45px;
    font-size: 20px !important;
    text-align:center !important;
}

.spot-selector > .form-input::-webkit-outer-spin-button,
.spot-selector > .form-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

  .spot-selector > .form-input[type=number] {
    -moz-appearance: textfield;
  }

/*Combobox (Cause Firefox can't into always visible arrow)*/
.vt-combobox {
  background-image: linear-gradient(45deg, transparent 50%, black 50%), linear-gradient(135deg, black 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.vt-combobox::-webkit-calendar-picker-indicator {
  opacity: 0;
}

.vt-combobox:focus {
  background-image: linear-gradient(45deg, black 50%, transparent 50%), linear-gradient(135deg, transparent 50%, black 50%);
  background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em, calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
  border-color: black;
  outline: 0;
}

/*Bus stop list*/
ul.route-stop-list {
  margin-top: 30px;
  margin-left: 50px;
  list-style-type: none;
}

li.route-stop {
  position: relative;
  margin: 0;
  padding-left: 20px;
}

.litime {
  position: absolute;
  left: -70px;
  margin: auto;
}

.listation {
  padding-left: 25px;
  margin: auto;
}

li.route-stop:before {
  background-color: $vt-secondary;
  width: 2px;
  height: 25px;
  content: '';
  position: absolute;
  top: 5px;
  bottom: 0px;
  left: 5px;
}
.stop-row {
    display: inline;
}
li.route-stop::after {
  content: '';
  position: absolute;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' viewBox='0 0 32 32' focusable='false'%3E%3Ccircle stroke='none' fill='%23646464' cx='16' cy='16' r='10'%3E%3C/circle%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: contain;
  left: -3px;
  top: 0px;
  width: 19px;
  height: 25px;
}

span.showmore {
  padding-left: 25px;
}

li.showmore:after {
  background: transparent;
}

.listationSubtitle {
  font-weight: normal;
  margin-top: 10px;
  margin-left: 25px;
  margin-bottom: 0;
}
/* Start the line further down on the first list item */
li.route-stop :first-child:before {
  top: 15px;
}

/* Stop the line short on the final list item */
li.route-stop:last-child:before {
  height: 3px;
}



/* Loading spinner */
.loading {
  display: inline-block;
  width: 80px;
  height: 80px;
}

  .loading:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid $vt-secondary;
    border-color: $vt-secondary transparent $vt-secondary transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
.loading-container {
  align-items: center;
  display: flex;
  width: auto;
  margin: 0 auto
}
.loading-container > p {
    vertical-align: central;
    font-size: 18px;
    margin-left: 20px;
}
.preloading-container {
  position: absolute;
  top: 50%;
  right: 50%;
  margin-top: -32px;
  margin-right: -32px;
  background: white;
  border-radius: 100%;
  padding: 0 !important;
  height: 80px;
  width: 80px;
}
.preloading-container > .loading {
  padding: 0;
  margin: 0;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


/* Table-lists */
.table-list {
  width: 100%;
  box-shadow: none;
}
  .table-list > thead > tr > th,
  .table-list > tbody > tr > td {
    padding: 1rem;
    border: 0;
  }
  .table-list > tbody > tr > td > .bold-text{
      text-align: right;
      margin-bottom: 0;
  }
  .table-list > tbody > tr:nth-of-type(odd) {
    background: $vt-secondary-light;
  }
  .table-list > tbody > tr:nth-of-type(even) {
    background: #ffffff;
  }
.data-list-icon {
    font-size: 24px;
}
.data-list-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 0.3rem;
}

/*Material table*/
.mat-table-list {
  width: 100%;
  border: 0;
  box-shadow: none;
}

  .mat-table-list > thead > tr > th,
  .mat-table-list > tbody > tr > td {
    padding: 1rem;
    border: 0;
  }

.entry-details {
  overflow: hidden;
  margin: 0 1rem;
}

tr.mat-row.detail-row {
  height: 0;
  padding: 0;
}
  tr.mat-row.detail-row > td {
      padding: 0;
  }


.mat-table-list > tbody > tr:nth-of-type(4n),
  .mat-table-list > tbody > tr:nth-of-type(4n-1) {
  background: $vt-secondary-light;
}

.mat-table-list > tbody > tr:nth-of-type(4n-2),
  .mat-table-list > tbody > tr:nth-of-type(4n-3) {
  background: #ffffff;
}

/*Modal popup window*/

.mat-dialog-actions {
    justify-content: flex-end;
    gap: 0.5rem;
}

/*Material checkboxes*/
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: $vt-secondary;
  color: white;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element, .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: $vt-secondary;
  color: white;
}

  /* Calendar and clock plugin style*/
  .owl-dt-calendar-table .owl-dt-calendar-cell-selected {
  background-color: $vt-secondary;
}
.owl-dt-container-buttons {
  color: $vt-secondary;
}

/*Map*/
.bus-stop-map-container {
  width: 60vw;
  height: 60vh;
  position: relative;
  overflow: hidden;
}
.bus-stop-map {
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  top: 0;
  overflow: hidden;
}

@media screen and (max-width: 992px) and (min-width: 768px) {
  *, ::after, ::before {
    box-sizing: content-box !important;
  }
  .spot-selector {
    display: flex;
    flex-direction: column-reverse;
    width: 3rem;
    margin: auto
  }
  .spot-selector > .form-input {
      width: 2rem;
  }
  .loading::after {
    margin: 6px;
    height: 55px;
    width: 55px;
  }
}

@media screen and (max-width: 767px) {
  *, ::after, ::before {
    box-sizing: border-box !important;
  }

  /* Universal toggles */
  .mobile-only {
    display: inherit;
  }

  .desktop-only {
    display: none !important;
  }



  .conditionally-hidden {
    display: none !important;
  }

  conditionally-shown {
    display: normal !important;
  }

  /*Bbuttons*/
  .control-buttons {
    flex-direction: column;
    width: 100%;
  }

    .control-buttons button {
      width: 100%;
      margin: 0 0 20px 0;
      padding: 10px 0;
    }

  /*Table list*/

  .table-list > thead {
    display: none;
  }

  .table-list > tbody > tr {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-self: flex-end;
    justify-content: space-between;
  }

    .table-list > tbody > tr > td {
      display: flex;
      flex-direction: column;
      order: 4;
      align-self: flex-end;
    }

    .table-list > tbody > tr > .expand
{
    order: 1;
    align-self: center;
    }

  .expand-btn {
    transition: 0.5s;
    border: none;
    font-size: 12px;
    background: white;
    border-radius: 50%;
    height: 24px;
    width: 24px;
  }

  .expand-btn-expanded {
      transition: 0.5s;
      transform: rotate(90deg);

  }
  .route {
      align-self: center !important;
  }
  .select-trip-message
  {
      width: 60%;
  }
  .spot-selector {
    display: flex !important;
    padding: 0 !important;
    align-items: center;
    gap: 0.5rem;
  }

    .spot-selector > .form-input {

    }
  .add-remove-btn {
    width: 2.5rem !important;
  }
}
